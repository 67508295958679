import React, { Component } from "react"
import { graphql, Link } from 'gatsby'
import WorkCarousel from '../components/workCarousel'
import GlobalBanner from '../components/globalBanner'
import Layout from '../components/layout'
import { GatsbyImage, getImage } from "gatsby-plugin-image";

class ProjectTemplate extends Component {
  render() {

    const data = this.props.data;
    const title = "More projects";
    let pageSubTitle = "";

    let backgroundImg = {
      backgroundImage: ''
    }

    if (data.wpProject.projectTypes != null) {
      if (data.wpProject.projectTypes.nodes != null || data.wpProject.projectTypes.nodes.length > 0) {
        data.wpProject.projectTypes.nodes.map((name) => {
          pageSubTitle = name.name;
        })
      }
    } else {
      pageSubTitle = "";
    }

    return (
      <Layout>
        <div className="project-template">

          <GlobalBanner pageTitle={data.wpProject.title}
                        pageSubTitle={pageSubTitle}
                        backgroundImg={backgroundImg} />

          <div className="ctn-main">

            <div className="back-link">
              <Link to="/work/"><span uk-icon="arrow-left"></span>Back to work page</Link>
            </div> {/* End .back-link */}

            {/* ----- SINGLE WORK DETAILS ----- */}
            <section className="uk-section uk-article">


              <div className="uk-container uk-section push-up">
                <div className="uk-position-relative uk-visible-toggle uk-light uk-card uk-card-default" data-uk-slideshow="ratio: 7:3; animation: push; min-height: 270;">
                  <ul className="uk-slideshow-items">
                    <li>
                      {data.wpProject.featuredImage !== null ? (
                        data.wpProject.featuredImage.node !== null || data.wpProject.featuredImage.node != undefined &&
                        data.wpProject.featuredImage.node.localFile !== null &&
                        data.wpProject.featuredImage.node.localFile.childImageSharp !== null ? (
                          <GatsbyImage image={getImage(data.wpProject.featuredImage.node.localFile)} />
                        ) : (
                          <img className="imgPath" src={data.wpProject.featuredImage.node.sourceUrl} alt={data.wpProject.featuredImage.node.altText} />
                        )
                      ) : (
                        <img className="default-img" src={'/img/single-project-default-img.jpg'} alt="Edelman Default Project Image" />
                      )
                    }
                    </li>
                  </ul>
                  <a className="uk-position-center-left uk-position-small uk-hidden-hover" data-uk-slidenav-previous="ratio: 1.5" data-uk-slideshow-item="previous"></a>
                  <a className="uk-position-center-right uk-position-small uk-hidden-hover" data-uk-slidenav-next="ratio: 1.5" data-uk-slideshow-item="next"></a>
                </div>
              </div>

              <div className="uk-container uk-container-small uk-dropcap ctn-small uk-padding" dangerouslySetInnerHTML={{ __html: data.wpProject.content }}></div>


              <div className="uk-container uk-margin-medium">
                <div className="uk-grid uk-grid-medium uk-child-width-1-2 uk-child-width-1-3@s" data-uk-grid data-uk-lightbox>
                    {(data.wpProject.projectACF.projectGallery != undefined || data.wpProject.projectACF.projectGallery != null )&&
                      data.wpProject.projectACF.projectGallery.map((node) => (
                      <div>
                        <a data-caption={node.title} title={node.title} href={node.sourceUrl}>
                          <img src={node.sourceUrl} alt={node.altText}/>
                        </a>
                      </div>
                    ))}
                </div>
              </div>

              <div className="uk-container uk-margin-medium uk-text-center uk-padding">
                {data.wpProject.projectACF.url != undefined &&
                  <a href={data.wpProject.projectACF.url} target="_blank">
                    <button className="uk-button uk-button-primary uk-button-large" dangerouslySetInnerHTML={{__html: "Visit "+ data.wpProject.title}} />
                  </a>
                }
              </div>



            </section>

            {/* ----- PROJECT META ----- */}
            <section className="ctn-width-viewport color-bg-lighter uk-padding">
                <div className="uk-container uk-container-small">
                  {/*<h1 className="colored uk-text-left uk-text-bold uk-margin-remove-adjacent uk-margin-remove-top" dangerouslySetInnerHTML={{ __html: data.wpProject.title }} />*/}

                  <h3>Meta Information</h3>
                  <div className="uk-margin-top uk-child-width-1-2@s uk-child-width-1-3@m uk-text-center" data-uk-grid>
                    <div className="uk-text-left">
                      <dt>Project Type</dt>
                      <dd>
                        {data.wpProject.projectTypes !== null &&
                          (data.wpProject.projectTypes.nodes != undefined || data.wpProject.projectTypes.nodes != null || data.wpProject.projectTypes.nodes.length > 0) &&
                          data.wpProject.projectTypes.nodes.map(({ name }) => (
                            // <p dangerouslySetInnerHTML={{__html: name}} />
                            <span className="item__cats">
                              <span className="uk-label uk-margin-small-right" dangerouslySetInnerHTML={{ __html: name }} />
                            </span>
                        ))}
                      </dd>

                    </div>
                    <div className="uk-text-left">
                      <dt>Launch Year</dt>
                      <dd>
                        {data.wpProject.projectYears !== null &&
                          (data.wpProject.projectYears.nodes != undefined || data.wpProject.projectYears.nodes != null || data.wpProject.projectYears.nodes.length > 0) &&
                          data.wpProject.projectYears.nodes.map(({ name }) => (
                            <p dangerouslySetInnerHTML={{__html: name}} />
                          ))
                        }
                      </dd>
                    </div>
                    <div className="uk-text-left">
                      <dt>Sector</dt>
                      <dd>
                        {data.wpProject.sectors !== null &&
                          (data.wpProject.sectors.nodes != undefined || data.wpProject.sectors.nodes != null || data.wpProject.sectors.nodes.length > 0) &&
                          data.wpProject.sectors.nodes.map(({ name }) => (
                            <span className="item__cats">
                              <span className="uk-label uk-margin-small-right" dangerouslySetInnerHTML={{ __html: name }} />
                            </span>
                            // <p dangerouslySetInnerHTML={{__html: name}} />
                        ))}
                      </dd>
                    </div>

                    <div className="uk-text-left uk-margin-small-bottom">
                      <dt>Technologies</dt>
                      <dd>
                        {data.wpProject.projectTechnologies !== null &&
                          (data.wpProject.projectTechnologies.nodes != undefined || data.wpProject.projectTechnologies.nodes != null || data.wpProject.projectTechnologies.nodes.length > 0) &&
                          data.wpProject.projectTechnologies.nodes.map(({ name }) => (
                            <span className="item__cats">
                              <span className="uk-label uk-margin-small-right">{name}</span>
                            </span>
                          ))
                        }
                      </dd>
                    </div>
                    <div className="uk-text-left uk-margin-small-bottom">
                      <dt>Features</dt>
                      <dd>
                        {data.wpProject.projectFeatures !== null &&
                          (data.wpProject.projectFeatures.nodes != undefined || data.wpProject.projectFeatures.nodes != null || data.wpProject.projectFeatures.nodes.length > 0) &&
                          data.wpProject.projectFeatures.nodes.map(({ name }) => (
                            <span className="item__cats">
                            <span className="uk-label uk-margin-small-right ">{name}</span>
                          </span>
                        ))}
                      </dd>
                    </div>
                    <div className="uk-text-left">
                      <dt>Team Members</dt>
                      <dd>
                        <ul>
                          {(data.wpProject.projectACF != undefined || data.wpProject.projectACF != null) &&
                          data.wpProject.projectACF.teamMembers !== null &&
                          data.wpProject.projectACF.teamMembers.map(( node ) => (
                            <li>
                              <a href={"/team/"+node.slug+"/"}><span className="">{node.title}</span></a>
                            </li>
                          ))}
                        </ul>
                      </dd>
                    </div>

                  </div> {/* ----- END: className="data-uk-grid" ----- */}
                </div> {/* ----- END: className="uk-container uk-container-small" ----- */}
            </section>

            {/* ----- WORK CAROUSEL ----- */}
            {/* <section className="ctn-width-viewport banner">
              <div className="uk-container work-ctn uk-dark">
                {data != undefined &&
                  <WorkCarousel works={data.allWpProject}
                                data={data.wpPage}
                                title={title}
                  />
                }
              </div>
            </section> */}
          </div> {/* End .ctn-main */}
        </div> {/* End .project-template */}
      </Layout>
    );
  }
}

export default ProjectTemplate

export const pageQuery = graphql`
query($slug: String!) {
  wpProject(slug: {eq: $slug}) {
    content
    title
    featuredImage {
      node {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
              sizes: "(min-width: 1429px) 1920px, 100vw"
            )
          }
        }
        sourceUrl
      }
    }
    projectACF {
      url
      projectGallery {
        title
        altText
        sourceUrl
      }
      mediaMobile {
        sourceUrl
      }
      teamMembers {
        ... on WpPerson {
          title
          slug
        }
      }
    }
    projectFeatures {
      nodes {
        name
      }
    }
    projectTypes {
      nodes {
        name
      }
    }
    projectTechnologies {
      nodes {
        name
      }
    }
    projectYears {
      nodes {
        name
      }
    }
    sectors {
      nodes {
        name
      }
    }
  }
}`
