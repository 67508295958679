import React, { Component } from 'react';
import { Link } from 'gatsby'
import { withPrefix } from 'gatsby-link';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

class WorkCarousel extends Component {
  render() {

    const works = this.props.works;
    const workAcf = this.props.data.homepageACF.workSection;

    return (
      <div className="ctn-main">
        {(workAcf !== undefined && works !== undefined) &&

          <div className="works mobile-padding">
            <h2 className="light uk-text-center space-around">
              {workAcf.sectionTitle}
              {workAcf.sectionSubtitle !== "" &&
                <span>{workAcf.sectionSubtitle}</span>
              }
            </h2>
            <div data-uk-slider>
                <div className="uk-position-relative">

                    <div className="uk-slider-container">
                        <ul className="uk-slider-items uk-grid-small uk-child-width-1-1 uk-child-width-1-2@s" data-uk-height-match="target: .uk-card-body" data-uk-grid>

                          {works.edges.map(({ node }) => (
                            <li key={node.slug} className="work">
                              <div className="uk-card uk-card-default">
                                {node.featuredImage !== undefined &&
                                  node.featuredImage !== null &&
                                    <div className="uk-card-media-top">
                                      <Link className="uk-cover-container" to={'/work/project/' + node.slug}>
                                        {node.featuredImage.node !== null &&
                                          node.featuredImage.node.localFile != null &&
                                          node.featuredImage.node.localFile.childImageSharp != null ? (
                                          <GatsbyImage image={getImage(node.featuredImage.node.localFile)} />
                                        ) : (
                                          <img className="imgPath" data-uk-cover src={node.featuredImage.source_url} alt={node.featuredImage.alt_text} />
                                        )}
                                      </Link>
                                    </div>
                                }
                                <div className="uk-card-body uk-grid-medium" data-uk-grid>
                                    <Link className="uk-cover-container" to={'/work/project/' + node.slug}>
                                      <h3 dangerouslySetInnerHTML={{ __html: node.title }} />
                                    </Link>
                                    <div className="summary">
                                      {/* {node.projectACF != null &&
                                        <p dangerouslySetInnerHTML={{ __html: node.projectACF.description }} />
                                      } */}
                                      <Link to={'/work/project/' + node.slug}>Read More</Link>
                                    </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>

                  <a className="uk-position-center-left-out arrow-slide" data-uk-slidenav-previous data-uk-slider-item="previous"><img src={withPrefix("img/arrow-left.png")} alt="Previous slide"/></a>
                  <a className="uk-position-center-right-out arrow-slide" data-uk-slidenav-next data-uk-slider-item="next"><img src={withPrefix("img/arrow-right.png")} alt="Next slide"/></a>
                </div>

              <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>

            </div>


            <Link className="button space-around centered" to={workAcf.buttonLink}>
              <span className="top left"></span>
              <span className="bottom left"></span>
              {workAcf.buttonText}
              <span className="top right"></span>
              <span className="bottom right"></span>
            </Link>
          </div>
        }
      </div>
    );
  }
}

export default WorkCarousel;
